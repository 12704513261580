define('embedbin/modules/preview', ['exports'], function (exports) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var Preview = (function () {
    function Preview($elem, model) {
      _classCallCheck(this, Preview);

      this.$elem = $elem;
      this.model = model;
    }

    _createClass(Preview, [{
      key: 'show',
      value: function show() {
        var _this = this;

        // Create an iframe,
        var iframe = document.createElement('iframe');
        iframe.frameBorder = '0';
        iframe.scrolling = "no";
        iframe.sandbox = "allow-scripts allow-top-navigation allow-forms allow-same-origin allow-popups";

        this.$elem.append(iframe);

        var doc = iframe.contentWindow.document;

        doc.open();
        doc.write("<!DOCTYPE html><html><head>");

        //write the css
        if (this.model.resources) {
          this.model.resources.forEach(function (src) {
            // css
            if (/\.css/.exec(src)) {
              doc.write('<link rel="stylesheet" href="' + src + '"></link>');
            }
          });
        }

        // write the styles.
        if (this.model.css) {
          doc.write('<style>' + this.model.css + '</style>');
        }

        // close the head and open the body.
        doc.write("</head><body>");

        if (this.model.html) {
          doc.write(this.model.html);
        }

        // add all the js.
        var bury = window.location.origin + '/bury/size.js';
        doc.write('<script type="text/javascript" src="' + bury + '"></script>');

        if (this.model.resources) {
          this.model.resources.forEach(function (src) {
            // css
            if (!/\.css/.exec(src)) {
              doc.write('<script type="text/javascript" src="' + src + '"></script>');
            }
          });
        }

        if (this.model.javascript) {
          doc.write('<script type="text/javascript">' + this.model.javascript + '</script>');
        }

        doc.close();

        var $heightSizing = $('.grid-embed-height-sizing'),
            $widthSizing = $('.grid-embed-width-sizing'),
            $heightSize = $('.grid-embed-height-size'),
            $widthSize = $('.grid-embed-width-size');

        this.handle = function (e) {
          var data;
          try {
            data = JSON.parse(e.data);
          } catch (e) {
            return false;
          }

          if (data.context !== 'iframe.resize') {
            return false;
          }

          if (data.height) {

            // Show the size things.
            $heightSizing.show();
            $widthSizing.show();
            var embedSize = $widthSize.outerWidth();
            $widthSize.find('span').text('' + embedSize);

            iframe.height = data.height;
            $heightSize.css({
              height: data.height
            });
            $heightSize.find('span').text(parseInt(data.height));

            // center the frame in the screen.
            var p = (_this.$elem.parent().height() - data.height) / 4;

            if (p > 0) {
              _this.$elem.css({
                marginTop: p
              });
              $heightSize.css({
                marginTop: p + 72
              });
            }
          }
        };
        window.addEventListener('message', this.handle);
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this.$elem.empty();
        window.removeEventListener("message", this.handle);
      }
    }]);

    return Preview;
  })();

  exports['default'] = Preview;
  ;
});