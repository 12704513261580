define('embedbin/helpers/truncate-url', ['exports', 'ember'], function (exports, _ember) {
  exports.truncateUrl = truncateUrl;

  function truncateUrl(params) {
    var url = params[0];

    if (!url) {
      return '';
    }

    var parts = url.split('/');

    return parts[parts.length - 1];
  }

  exports['default'] = _ember['default'].Helper.helper(truncateUrl);
});