define('embedbin/router', ['exports', 'ember', 'embedbin/config/environment'], function (exports, _ember, _embedbinConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _embedbinConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('bin', { path: '/b/:id' });
    this.route('embed', { path: '/e/:id' });

    this.route('nope', { path: '/404' });
  });

  exports['default'] = Router;
});