define('embedbin/routes/bin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    bins: _ember['default'].inject.service('bins'),
    model: function model(params) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.get('bins').auth().then(function (uid) {
          _this.get('bins').bin(params.id).then(function (data) {

            if (!data) {
              resolve(data);
            } else {
              data.updateable = data.u === uid;
              data.show = true;

              if (!data.resources) {
                data.resources = [];
              }
              return resolve(data);
            }
          });
        });
      });
    },
    afterModel: function afterModel(data) {
      if (!data) {
        this.transitionTo('nope');
      }
    }
  });
});