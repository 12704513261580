define('embedbin/services/bins', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    base: null,
    attrs: ['css', 'javascript', 'html', 'resources', 'u', 'width', 'height'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('base', new Firebase('https://simplebin.firebaseio.com'));

      // Sets up auth.
      var auth = this.get('base').getAuth();

      if (auth) {
        this.set('uid', auth.uid);
      } else {
        this.set('uid', null);
      }
    },

    uuid: function uuid() {
      var _u = function _u() {
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0,
              v = c === 'x' ? r : r & 0x3 | 0x8;
          return v.toString(16);
        });
      };

      return _u().substr(0, 8);
    },

    auth: function auth() {
      var _this = this;

      // auth the user.
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this.get('uid')) {
          resolve(_this.get('uid'));
          return;
        }

        _this.get('base').authAnonymously(function (error, data) {
          if (error) {
            console.log("Login Failed!", error);
            reject(error);
          } else {
            _this.set('uid', data.uid);
            resolve(data.uid);
          }
        });
      });
    },

    strip: function strip(data) {
      return this.get('attrs').reduce(function (obj, attr) {
        if (data[attr]) {
          obj[attr] = data[attr];
        }
        return obj;
      }, {});
    },

    save: function save(data) {
      var _this2 = this;

      var uuid = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      // Create a UUID for the data.
      uuid = uuid ? uuid : this.uuid();

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this2.auth().then(function (uid) {

          // Make sure data is a normal, plain object.
          var obj = _this2.strip(data);
          obj.created = new Date().getTime();
          obj.u = uid;

          return _this2.get('base').child('bins').child(uuid).set(obj).then(function () {
            Em.set(data, 'id', uuid);
            resolve(data);
          });
        });
      });
    },
    update: function update(data) {
      var obj = this.strip(data);

      obj.updated = new Date().getTime();

      return this.get('base').child('bins').child(data.id).set(obj).then(function () {
        return data;
      });
    },
    fork: function fork(data) {
      var _this3 = this;

      var uuid = this.uuid();

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this3.save(data, uuid).then(function (obj) {
          resolve(obj);
        });
      });
    },
    bin: function bin(id) {
      return this.get('base').child('bins').child(id).once('value').then(function (e) {
        var data = e.val();
        if (data) {
          data.id = id;
        }
        return data;
      });
    }

  });
});