define('embedbin/components/create-grid', ['exports', 'ember', 'embedbin/modules/preview'], function (exports, _ember, _embedbinModulesPreview) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({

    bins: _ember['default'].inject.service('bins'),
    user: _ember['default'].inject.service('user'),

    classNames: ['create-grid'],

    verticalRatio: 0.5,
    horizontalTopRatio: 1 / 3,
    horizontalBottomRatio: 2 / 3,

    init: function init() {
      this.set('heights', Em.Object.create({
        resources: null,
        css: null,
        javascript: null,
        html: null
      }));

      this.set('changed', false);
      this.set('saveable', false);
      this.set('updating', false);

      this.set('editorWidth', null);

      this._super.apply(this, arguments);
    },

    actions: {
      // Sets all the editorchanges.
      edit: function edit(lang, value) {
        this.set('changed', true);
        this.set('model.' + lang, value);
      },
      run: function run() {
        this.set('changed', false);
        // Create the sandbox iframe.
        if (this.get('preview')) {
          this.get('preview').destroy();
        }

        var preview = new _embedbinModulesPreview['default'](this.$('.grid-preview'), this.get('model'));
        preview.show();
        this.set('preview', preview);
        this.set('saveable', true);
      },
      toggle: function toggle(lang) {
        // set the ratio so it's the top + 20 pixels.
        var $elem = this.$('.grid-' + lang),
            ratio = undefined;

        if ($elem.hasClass('inactive')) {
          $elem.removeClass('inactive');
          if (lang === 'javascript') {
            ratio = (window.innerHeight - 200) / window.innerHeight;
          } else {
            ratio = ($elem.offset().top + 200) / window.innerHeight;
          }
        } else {
          $elem.addClass('inactive');
          if (lang === 'javascript') {
            ratio = (window.innerHeight - 20) / window.innerHeight;
          } else {
            ratio = ($elem.offset().top + 20) / window.innerHeight;
          }
        }

        if (lang === 'html') {
          this.horizontalTopRatio = ratio;
        } else {
          this.horizontalBottomRatio = ratio;
        }

        this.resize();
      },
      save: function save() {
        var _this = this;

        if (!this.get('saveable')) {
          return false;
        }

        this.currentDimensions();
        this.get('bins').save(this.get('model')).then(function (data) {
          _this.sendAction("moveTo", data.id);
        });
      },
      update: function update() {
        var _this2 = this;

        if (this.get('updating')) {
          return false;
        }

        this.set('updating', true);
        var start = new Date().getTime();
        this.currentDimensions();
        this.get('bins').update(this.get('model')).then(function (data) {
          var diff = 2000 - (new Date().getTime() - start);
          diff = diff <= 0 ? 1 : diff;

          setTimeout(function () {
            _this2.set('updating', false);
            _this2.set('saveable', false);
          }, diff);
        });
      },
      fork: function fork() {
        var _this3 = this;

        this.get('bins').fork(this.get('model')).then(function (data) {
          _this3.sendAction("moveTo", data.id);
        });
      },
      toggleDrawer: function toggleDrawer() {
        var $drawer = this.$('.grid-copy-drawer');

        if ($drawer.hasClass('active')) {
          $drawer.removeClass('active');
          $drawer.css({ height: 30 });
        } else {
          $drawer.addClass('active');
          $drawer.css({ height: $drawer.find('.drawer-content').outerHeight() + 30 });
        }
      }
    },

    isCurrent: _ember['default'].computed('model.u', function () {
      var u = this.get('model.u'),
          c = this.get('user').current();

      if (!u || !c) {
        return false;
      }

      return u === c;
    }),

    currentDimensions: function currentDimensions() {
      // figure out the ratio
      var width = $('.grid-preview iframe').width(),
          height = $('.grid-preview iframe').height(),
          ratio = height / width;

      // We always want this to be 700.
      this.set('model.width', 700);

      // We need to figure this out.
      this.set('model.height', parseInt(700 * ratio));
    },

    resize: function resize() {
      var _this4 = this;

      var direction = arguments.length <= 0 || arguments[0] === undefined ? 0 : arguments[0];

      // Deal with the sizing of elements.
      var width = window.innerWidth,
          height = window.innerHeight,
          menuWidth = 200,
          headerSize = 20,
          bumper = headerSize / height,
          one = 1 / height;

      // Lets deal with the vertical breaker.
      var verticalBreak = width * this.verticalRatio;

      if (verticalBreak <= menuWidth + 100) {
        verticalBreak = menuWidth + 100;
        this.verticalRatio = verticalBreak / width;
      } else if (verticalBreak >= width - 250) {
        verticalBreak = width - 250;
        this.verticalRatio = verticalBreak / width;
      }

      // These are the horizontal breakers.
      var ratios = [this.horizontalTopRatio, this.horizontalBottomRatio];

      for (var i = 0; i < ratios.length; i++) {
        var r = ratios[i];

        //make sure we aren't close to the boundarys.
        if (i === 0 && r < bumper) {
          ratios[i] = bumper;
        } else if (i === ratios.length - 1 && r >= 1 - bumper) {
          ratios[i] = 1 - bumper;
        }

        var before = ratios[i - 1],
            after = ratios[i + 1];

        // Now deal with making sure we aren't too close to each other.
        if (before) {
          if (before + bumper >= r && direction > -1) {
            ratios[i] = before + bumper;
          } else if (r - bumper < before) {
            ratios[i - 1] = r - bumper;
          }
        }
      };

      // make sure we didn't got over bounds.
      ratios = ratios.map(function (r, i) {
        if (r < bumper * (i + 1)) {
          return bumper * (i + 1);
        } else if (r >= 1 - bumper * (ratios.length - i)) {
          return 1 - bumper * (ratios.length - i);
        }
        return r;
      });

      // Set all these values.
      var _ratios = ratios;

      var _ratios2 = _slicedToArray(_ratios, 2);

      this.horizontalTopRatio = _ratios2[0];
      this.horizontalBottomRatio = _ratios2[1];

      var horizontalTopBreak = height * this.horizontalTopRatio,
          horizontalBottomBreak = height * this.horizontalBottomRatio;

      // Set right guy initally to 40% of the window.
      var $left = this.$('.grid-left'),
          $right = this.$('.grid-right'),
          $verticalBreaker = this.$('.vertical-breaker'),
          $drawer = this.$('.grid-copy-drawer');

      $left.css({
        height: height,
        width: verticalBreak - menuWidth + 'px'
      });

      $right.css({
        height: height,
        left: verticalBreak + 'px',
        width: width - verticalBreak + 'px'
      });

      $verticalBreaker.css({
        height: height,
        left: verticalBreak + 'px'
      });

      $drawer.css({
        left: verticalBreak + 1 + 'px'
      });

      this.set('editorWidth', verticalBreak - menuWidth);

      // Set up the sub breakers
      var $html = this.$('.grid-html'),
          $css = this.$('.grid-css'),
          $js = this.$('.grid-javascript'),
          $horizontalTopBreaker = this.$('.horizontal-top'),
          $horizontalBottomBreaker = this.$('.horizontal-bottom'),
          leftWidth = verticalBreak - menuWidth;

      $horizontalTopBreaker.css({
        top: horizontalTopBreak,
        width: leftWidth
      });

      $html.css({
        top: 0,
        height: horizontalTopBreak,
        width: leftWidth
      });

      $css.css({
        top: horizontalTopBreak,
        height: horizontalBottomBreak - horizontalTopBreak,
        width: leftWidth
      });

      $horizontalBottomBreaker.css({
        top: horizontalBottomBreak,
        width: leftWidth
      });

      $js.css({
        top: horizontalBottomBreak,
        height: height - horizontalBottomBreak,
        width: leftWidth
      });

      // If the height of the element is small, set it as inactive.
      var breakers = [$horizontalTopBreaker, $horizontalBottomBreaker];

      var names = ['html', 'css', 'javascript'];

      [$html, $css, $js].forEach(function ($e, i) {
        var a = [$e];
        if (i > 0) {
          a.push(breakers[i - 1]);
        }

        _this4.set('heights.' + names[i], $e.height());

        if ($e.height() <= headerSize) {
          a.forEach(function (k) {
            return k.addClass('inactive');
          });
        } else if ($e.height() > headerSize) {
          a.forEach(function (k) {
            return k.removeClass('inactive');
          });
        }
      });

      // deal with the .grid-sizing
      var embedSize = $('.grid-embed-width-size').outerWidth();
      $('.grid-embed-width-size span').text('' + embedSize);
    },

    start: function start() {
      var _this5 = this;

      this.resize();

      this._handleResize = function () {
        _this5.resize();
      };

      $(window).on('resize', this._handleResize);

      var mover = function mover($e, cb) {
        var last = 0,
            dir = null;

        var mousemove = function mousemove(e) {
          // Set the
          cb(e.clientX, e.clientY);

          dir = last < e.clientY ? 1 : -1;

          _this5.resize(dir);

          last = e.clientY;
        };

        $e.on('mousedown', function () {
          $(window).on('mousemove', mousemove);
          $('.grid-overlay').show();
          $(window).one('mouseup', function () {
            $(window).off('mousemove', mousemove);
            $('.grid-overlay').hide();
          });
        });
      };

      mover(this.$('.vertical-breaker'), function (x, y) {
        _this5.verticalRatio = x / window.innerWidth;
      });

      mover(this.$('.horizontal-top'), function (x, y) {
        _this5.horizontalTopRatio = y / window.innerHeight;
      });

      mover(this.$('.horizontal-bottom'), function (x, y) {
        _this5.horizontalBottomRatio = y / window.innerHeight;
      });

      if (this.get('model.show')) {
        this.send('run');
      }
    },

    didInsertElement: function didInsertElement() {
      var _this6 = this;

      this._super.apply(this, arguments);

      // This is a hack.
      setTimeout(function () {
        _this6.start();
      }, 20);
    },
    willDestoryElement: function willDestoryElement() {
      $(window).off('resize', this._handleResize);
    },
    willClearRender: function willClearRender() {
      $(window).off('resize', this._handleResize);
    },

    embedCode: _ember['default'].computed('model.id', 'model.width', 'model.height', function () {
      var id = this.get('model.id'),
          width = this.get('model.width'),
          height = this.get('model.height');

      if (id && width && height) {
        var html = '<iframe src="https://embedmaker.com/e/' + id + '" width="' + width + '" height="' + height + '" scrolling="no" frameborder="0" allowfullscreen></iframe>\n<script src="https://embedmaker.com/front/resize.js"></script>';
        return html;
      }

      return '';
    })

  });
});