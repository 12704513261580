define('embedbin/components/add-resources', ['exports', 'ember'], function (exports, _ember) {

  var sources = [{
    name: 'jQuery 2.2.3',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.3/jquery.min.js'
  }, {
    name: 'Chart.js 2.0.1',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.0.1/Chart.min.js'
  }, {
    name: 'd3 3.5.16',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.16/d3.min.js'
  }, {
    name: 'Foundation 6.2.1',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/foundation/6.2.1/foundation.min.css'
  }, {
    name: 'Moment.js 2.13.0',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.13.0/moment.min.js'
  }, {
    name: 'p5 0.5.0',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/p5.js/0.5.0/p5.min.js'
  }];

  exports['default'] = _ember['default'].Component.extend({
    resource: null,
    classNames: ['add-resources'],
    actions: {
      add: function add() {
        var r = this.get('resource');

        if (r) {
          this.get('resources').insertAt(0, r);
        }
        this.set('resource', null);
      },
      remove: function remove(url) {
        this.get('resources').removeObject(url);
      },
      defaults: function defaults() {
        var _this = this;

        // Open a modal?

        var $overlay = $('<div class="grid-sources-overlay"></div>'),
            $content = $('<div class="grid-sources-content"><a class="close" href="#">&times;</a></div>');

        this.$().append($overlay);
        $overlay.append($content);

        var $ul = $('<ul></ul>'),
            $li = undefined;

        sources.forEach(function (source) {
          $li = $('<li><a href="#"><b>' + source.name + '<b><span><i class="fa fa-plus-circle"></i></span></a></li>');
          $li.data('src', source.src);
          $ul.append($li);
        });

        $content.append($ul);

        $ul.find('a').on('click', function (e) {
          _this.set('resource', $(e.target).parents('li').data('src'));
          _this.send('add');
          $overlay.remove();
          return false;
        });

        $overlay.on('click', function () {
          $overlay.remove();
          return false;
        });

        $content.find('a.close').on('click', function () {
          $overlay.remove();
          return false;
        });
      }
    }
  });
});