define('embedbin/components/code-editor', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    heightChanged: _ember['default'].observer('height', function () {
      var height = this.get('height'),
          editor = this.get('editor');

      if (height && editor) {
        editor.resize();
      }
    }),

    widthChanged: _ember['default'].observer('width', function () {
      var width = this.get('width'),
          editor = this.get('editor');

      if (width && editor) {
        editor.resize();
      }
    }),

    start: function start() {
      var _this = this;

      var editor = ace.edit(this.$()[0]);
      editor.setTheme("ace/theme/xcode");
      editor.setOption("showPrintMargin", false);

      var session = editor.getSession();
      session.setMode('ace/mode/' + this.lang);
      session.setUseWorker(false);
      session.setUseSoftTabs(true);
      session.setTabSize(2);
      session.setUseWrapMode(true);

      session.on('change', function () {
        _this.sendAction("editorChange", _this.get('lang'), editor.getValue());
      });

      if (this.get('value')) {
        editor.setValue(this.get('value'), 1);
      }
      this.set('editor', editor);
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      // This is a hack.
      setTimeout(function () {
        _this2.start();
      }, 20);
    }
  });
});