define('embedbin/services/user', ['exports', 'ember', 'embedbin/modules/utils'], function (exports, _ember, _embedbinModulesUtils) {

  var cookie = {
    name: '_ebin',
    set: function set(name, value, days, secure) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      }
      document.cookie = name + "=" + value + expires + "; path=/" + (secure ? '; secure' : '');
    },
    get: function get(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    },
    del: function del(name) {
      browser.cookie.set(name, "", -1);
    }
  };

  exports['default'] = _ember['default'].Service.extend({
    current: function current() {
      // returns a uuid.
      var str = cookie.get(cookie.name);

      if (!str) {
        str = (0, _embedbinModulesUtils.uuid)();
        cookie.set(cookie.name, str, 365, window.location.protocol === 'https:');
      }

      return str;
    }
  });
});