define('embedbin/routes/index', ['exports', 'ember'], function (exports, _ember) {

  var html = '<div class="embed">\n  <!--\n    Build you embed inside this <div>. The JavaScript resize\n    function will use the height of this div to create set the\n    correct functions.\n  -->\n  <p>\n    Yay! This is your first embed, start editing.\n  </p>\n</div>\n';

  var js = '// The resize function will be called initially and whenever the\n// embed resizes. Use it to tell the parent the correct height\n// of the embed.\nvar resize = function(){\n  // Grab the .embed wrapper.\n  var wrapper = document.querySelector(\'.embed\');\n\n  // resizeHeight is a global function that will post the height\n  // of the embed to the parent.\n  resizeHeight(wrapper.offsetHeight);\n};\n\n//Calls the resize function when\nwindow.addEventListener(\'resize\', resize);\n\n// Call the resize functions initially.\nresize();\n';

  var css = '/* Some resets on the html and body.*/\nhtml, body {\n  width:100%;\n  height: 100%;\n  padding: 0;\n  margin: 0;\n}\n\n/* wrapper around your embed.*/\n.embed {\n  background: #fff;\n  padding: 50px;\n}\n\np {\n  font-size:16px;\n  text-align:center;\n  border: 1px solid #ddd;\n  padding: 20px;\n}\n';

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return {
        javascript: js,
        css: css,
        html: html,
        resources: []
      };
    }
  });
});